.consulting-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.consulting-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.consulting-navlink {
  display: contents;
}
.consulting-image {
  width: var(--dl-size-size-medium);
  height: auto;
  text-decoration: none;
}
.consulting-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.consulting-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.consulting-text {
  transition: 0.3s;
  text-decoration: none;
}
.consulting-text:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink01 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.consulting-navlink01:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink02 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.consulting-navlink02:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink03 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.consulting-navlink03:hover {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink04 {
  display: contents;
}
.consulting-buttons {
  width: 98px;
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  text-decoration: none;
}
.consulting-icon {
  fill: #d19d54;
  flex: 1;
  color: var(--dl-color-scheme-brown);
  width: 30px;
  height: 30px;
}
.consulting-burger-menu {
  display: none;
}
.consulting-icon2 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}
.consulting-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 982px;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.consulting-sidebar {
  width: 300px;
  height: 700px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.consulting-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.consulting-image1 {
  width: 100px;
  height: 100px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.consulting-text01 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.consulting-text02 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.consulting-text03 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.consulting-text04 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.consulting-text06 {
  color: var(--dl-color-scheme-brown);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.consulting-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.consulting-banner {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-fourunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fourunits);
  background-size: cover;
  justify-content: center;
  background-image: linear-gradient( rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.5) ),url("https://images.unsplash.com/photo-1553877522-43269d4ea984?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDF8fGNvbnN1bHRpbmd8ZW58MHx8fHwxNjg5Nzk4NDgyfDA&ixlib=rb-4.0.3&w=1500");
  background-position: center;
  box-shadow: 0 0 8px 8px white inset;
}
.consulting-container2 {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.consulting-text07 {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.consulting-text08 {
  color: #ffffff;
  max-width: 70%;
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.consulting-banner1 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.consulting-text12 {
  width: 60%;
  font-size: 2.5rem;
  text-align: center;
}
.consulting-text13 {
  width: 60%;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.consulting-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.consulting-container3 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  grid-template-columns: 1fr 1fr;
}
.consulting-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.consulting-container4 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.consulting-navlink05 {
  display: contents;
}
.consulting-image2 {
  height: 4rem;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.consulting-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.consulting-navlink06 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: 0px;
  text-decoration: none;
}
.consulting-navlink06:hover {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink07 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.consulting-navlink07:hover {
  color: red;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink08 {
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.consulting-navlink08:hover {
  color: red;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.consulting-navlink09 {
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.consulting-navlink09:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.consulting-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: #D9D9D9;
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.consulting-container5 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.consulting-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.consulting-container7 {
  display: flex;
  align-items: space-between;
  flex-direction: row;
}
.consulting-icon4 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.consulting-icon6 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 991px) {
  .consulting-desktop-menu {
    display: none;
  }
  .consulting-burger-menu {
    display: flex;
  }
  .consulting-mobile-menu {
    flex: 1;
    width: 100%;
  }
  .consulting-text08 {
    width: 100%;
  }
  .consulting-text12 {
    font-size: 2.5rem;
  }
  .consulting-text13 {
    width: 80%;
    max-width: 100%;
  }
}
@media(max-width: 767px) {
  .consulting-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .consulting-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .consulting-mobile-menu {
    width: 767px;
    height: 551px;
  }
  .consulting-sidebar {
    /*height: 70vh;*/
  }
  .consulting-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .consulting-text08 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .consulting-banner1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .consulting-text12 {
    width: 80%;
  }
  .consulting-text13 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .consulting-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .consulting-container3 {
    grid-template-columns: 1fr;
  }
  .consulting-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .consulting-image2 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .consulting-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .consulting-container5 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .consulting-text14 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .consulting-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .consulting-mobile-menu {
    width: 479px;
    height: 655px;
    padding: 16px;
  }
  .consulting-banner {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .consulting-text08 {
    max-width: initial;
  }
  .consulting-banner1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .consulting-text12 {
    width: 95%;
    font-size: 2rem;
  }
  .consulting-text13 {
    width: 95%;
  }
  .consulting-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .consulting-footer {
    padding: var(--dl-space-space-unit);
  }
  .consulting-nav1 {
    flex-direction: column;
  }
  .consulting-navlink06 {
    margin-bottom: 10px;
  }
  .consulting-navlink07 {
    margin-bottom: 10px;
  }
  .consulting-navlink08 {
    margin-bottom: 10px;
  }
  .consulting-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .consulting-container5 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .consulting-text14 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
