.signup-container {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-sixunits);
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.signup-container1 {
  width: 90%;
  height: 100%;
  display: flex;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  min-height: 80vh;
  align-items: flex-start;
}
.signup-container2 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  min-height: 80vh;
  align-items: flex-start;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1633250391894-397930e3f5f2?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDE5fHxtb2JpbGUlMjBhcHB8ZW58MHx8fHwxNjkxNTIzOTEzfDA&ixlib=rb-4.0.3&h=1500");
  background-position: center;
}
.signup-container3 {
  flex: 0 0 auto;
  width: 50%;
  height: 100%;
  display: flex;
  min-height: 80vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.signup-text {
  color: var(--dl-color-scheme-brown);
  width: 100%;
  text-align: center;
}
.signup-text1 {
  width: 100%;
  font-size: 15px;
  font-style: normal;
  margin-top: 20px;
  text-align: center;
  font-weight: 100;
}
.signup-container4 {
  flex: 0 0 auto;
  width: 80%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.signup-container5 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  min-height: 40vh;
  margin-top: 20px;
}
.signup-textinput {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-color: #ffffff;
}
.signup-textinput:hover {
  cursor: pointer;
}
.signup-textinput1 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-color: #ffffff;
}
.signup-textinput1:hover {
  cursor: pointer;
}
.signup-textinput2 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-color: #ffffff;
}
.signup-textinput2:hover {
  cursor: pointer;
}
.signup-textinput3 {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-color: #ffffff;
}
.signup-textinput3:hover {
  cursor: pointer;
}
.signup-textinput4 {
  color: #000000;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-color: #ffffff;
}
.signup-textinput4:hover {
  cursor: pointer;
}
.signup-button {
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s;
  border-radius: 10px;
}
.signup-button:hover {
  cursor: pointer;
  transform: scale(1.02);
}
.signup-container6 {
  width: 100%;
  height: 32px;
  display: flex;
  align-items: flex-start;
}
.signup-navlink {
  width: 100%;
  height: 100%;
  margin-top: 20PX;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
}
.signup-navlink:hover {
  color: var(--dl-color-scheme-brown);
  cursor: pointer;
  font-weight: bold;
}
.signup-navlink1 {
  display: contents;
}
.signup-profile {
  width: 100%;
  margin-top: 5vh;
  text-align: center;
  transition: 0.3s;
  text-decoration: none;
}

.signup-text2 {
  font-style: normal;
  transition: 0.3s;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
.signup-text2:hover {
  color: var(--dl-color-scheme-brown);
  cursor: pointer;
  font-weight: bold;
}
.signup-profile1 {
  display: flex;
  align-self: center;
  margin-top: 2vh;
  align-items: center;
}
.signup-image {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.signup-text3 {
  font-style: normal;
  font-weight: 600;
  margin-left: var(--dl-space-space-halfunit);
}
@media(max-width: 991px) {
  .signup-container1 {
    width: 100%;
  }
  .signup-text1 {
    font-size: 15px;
  }
}
@media(max-width: 767px) {
  .signup-container2 {
    display: none;
  }
  .signup-container3 {
    width: 100%;
  }
}
@media(max-width: 479px) {
  .signup-container {
    display: flex;
    padding: var(--dl-space-space-twounits);
    justify-content: center;
  }
  .signup-text2 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }
  .signup-text3 {
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
  }
}
