.home-container {
  width: 100%;
  display: flex;
  position: relative;
  min-height: 100vh;
  overflow-x: hidden;
  transition: 0.3s;
  align-items: center;
  flex-direction: column;
}

.home-main {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-navbar-interactive {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 0px;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: 0px;
  justify-content: space-between;
}
.home-image {
  width: var(--dl-size-size-medium);
  height: auto;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text {
  transition: 0.3s;
  text-decoration: none;
}
.home-text:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.home-navlink {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.home-navlink01 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink01:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.home-navlink02 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink02:hover {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
}
.home-buttons {
  width: 98px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-navlink03 {
  display: contents;
}
.home-icon {
  fill: #d19d54;
  flex: 1;
  color: var(--dl-color-scheme-brown);
  width: 30px;
  height: 30px;
  text-decoration: none;
}

.nav-profile {
  width: 125px;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: center;
  flex-direction: row;
  /* border: 1px solid; */
  justify-content: space-between;
}
.nav-profile:hover {
  color: #d19d54;
}
.nav-icon {
  fill: #d19d54;
  flex: 1;
  color: var(--dl-color-scheme-brown);
  width: 20px;
  height: 20px;
  text-decoration: none;
}

.home-burger-menu {
  display: none;
}
.home-icon02 {
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
}

.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 982px;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.home-mobile-menu-open {
  animation: slideOutLeft 0.4s;
  right:  0px;
  top: 0px;
  width: 100%;
  height: 982px;
  padding: 32px;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  background-color: #fff;
}

.home-mobile-menu-close {
  animation: slideOutRight 0.4s;
  right:  0px;
  top: 0px;
  width: 100%;
  height: 982px;
  padding: 32px;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  z-index: 100;
  background-color: #fff;

  display: flex;
  position: fixed;    
  display: block;
  right: -800px;
  z-index: 3;
}

.side-nav-bar-close {
  display: flex;
  position: fixed;    
  display: block;
  right: -350px;
  z-index: 3;
}

.animation-slide-out-right {
  animation: slideOutRight 0.4s;
}

@keyframes slideOutLeft {
  from { right: -350px; }
  to   { right:  0px; }
}

@keyframes slideOutRight {
  from { right: 0px; }
  to   { right: -800px; }
}

.home-sidebar {
  width: 300px;
  height: 700px;
  display: flex;
  padding: var(--dl-space-space-twounits);
  flex-direction: column;
  justify-content: space-between;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image1 {
  width: 100px;
  height: 100px;
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text01 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.nav-text01 {
  margin-bottom: var(--dl-space-space-threeunits);
  display: contents;
}
.home-text02 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text03 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text04 {
  margin-bottom: var(--dl-space-space-threeunits);
}
.home-text06 {
  color: var(--dl-color-scheme-brown);
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}
.home-hero {
  height: 550px;
  position: relative;
  align-self: center;
  padding-top: 0px;
  padding-bottom: 4px;
  background-size: cover,contain;
  background-image: linear-gradient(rgba(0, 0, 0, 0.0),rgba(0, 0, 0, 0.9)),url(../assets/giphy-1500w.gif);
  background-repeat: no-repeat;
  background-position: center,right;
}
.home-container01 {
  flex: 0 0 auto;
  width: auto;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container02 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container04 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container05 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container06 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container07 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-container08 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}
.home-max-width {
  border: 2px dashed rgba(120, 120, 120, 0.4);
  margin-top: var(--dl-space-space-threeunits);
}
.home-heading-container {
  flex: 1;
  display: flex;
  max-width: 100%;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-text07 {
  color: #ffffff;
  font-size: 3rem;
  text-align: center;
  margin-bottom: var(--dl-space-space-halfunit);
}
.home-text08 {
  color: #ffffff;
  font-size: 18px;
  text-align: center;
  line-height: 1.44;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-text10 {
  font-size: 18px;
  line-height: 1.44;
}
.home-primary {
  border: 1px solid;
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
  background-color: transparent;
}

.home-services {
  display: flex;
  align-self: center;
  transition: 0.3s;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-image: linear-gradient(rgb(0 8 11),#fff);
}

.home-max-width1 {
  height: auto;
  position: relative;
  flex-direction: column;
}
.home-heading-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-text-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text12 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text13 {
  color: #ffffff;
}
.home-cards-container {
  width: 100%;
  height: auto;
  display: flex;
  grid-gap: 20px;
  align-self: flex-start;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-unit);
}
.home-navlink04 {
  display: contents;
}
.home-component {
  text-decoration: none;
}
.home-navlink05 {
  display: contents;
}
.home-component1 {
  text-decoration: none;
}
.home-cards-container1 {
  width: 100%;
  display: flex;
  grid-gap: 20px;
}
.home-navlink06 {
  display: contents;
}
.home-component2 {
  text-decoration: none;
}
.home-navlink07 {
  display: contents;
}
.home-component3 {
  text-decoration: none;
}
.home-max-width2 {
  flex-direction: column;
}
.home-text-container1 {
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
}
.home-text17 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text18 {
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text20 {
  color: var(--dl-color-scheme-black80);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-tab-selector-cards-container {
  width: 100%;
  height: 378px;
  display: flex;
  position: relative;
  grid-row-gap: 40px;
  margin-bottom: var(--dl-space-space-unit);
  grid-column-gap: 20px;
  justify-content: space-between;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.home-link {
  display: contents;
}
.home-component4 {
  text-decoration: none;
}
.home-link1 {
  display: contents;
}
.home-component5 {
  text-decoration: none;
}
.home-tab-selector-cards-container1 {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  grid-row-gap: 40px;
  grid-column-gap: 20px;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(3, 1fr);
}
.home-link2 {
  display: contents;
}
.home-component6 {
  text-decoration: none;
}
.home-link3 {
  display: contents;
}
.home-component7 {
  text-decoration: none;
}
.home-about {
  background-color: var(--dl-color-scheme-lightbrown);
}
.home-max-width3 {
  flex-direction: row;
  justify-content: space-between;
}
.home-text-container2 {
  flex: 1;
  width: 40%;
  display: flex;
  align-items: flex-start;
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-text21 {
  color: var(--dl-color-scheme-brown);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text22 {
  margin-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-text26 {
  color: var(--dl-color-scheme-black80);
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-checklist {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-check-item {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon04 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text27 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon06 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text28 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item2 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon08 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text29 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item3 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon10 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text30 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item4 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: row;
}
.home-icon12 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text31 {
  font-style: normal;
  font-weight: 500;
}
.home-check-item5 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-icon14 {
  fill: var(--dl-color-scheme-brown);
  width: 18px;
  height: 18px;
  margin-right: var(--dl-space-space-unit);
}
.home-text32 {
  font-style: normal;
  font-weight: 500;
}
.home-image2 {
  width: 434px;
  height: 500px;
  object-fit: cover;
}
.home-process {
  background-color: #545151;
}
.home-steps {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-text33 {
  color: #ffffff;
  font-size: 3rem;
}
.home-text35 {
  color: rgb(153, 153, 153);
  font-size: 1.15rem;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text36 {
  color: #ffffff;
}
.home-container09 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-step {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container10 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container11 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon16 {
  fill: #595959;
  width: 24px;
  height: 24px;
}
.home-line1 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container12 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text38 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text40 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  text-align: center;
}
.home-step1 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container13 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line2 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container14 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon18 {
  fill: #595959;
  width: 24px;
  height: 24px;
}
.home-line3 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container15 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text43 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text44 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  text-align: center;
}
.home-step2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container16 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line4 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container17 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon20 {
  fill: #595959;
  width: 24px;
  height: 24px;
}
.home-line5 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container18 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text47 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text49 {
  color: rgb(89, 89, 89);
  font-size: 0.75rem;
  text-align: center;
}
.home-text50 {
  color: #ffffff;
}
.home-text51 {
  color: #ffffff;
}
.home-step3 {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-container19 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: 2px;
  padding-right: 2px;
  flex-direction: row;
  justify-content: center;
}
.home-line6 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: #D9D9D9;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container20 {
  flex: 0 0 auto;
  width: var(--dl-size-size-small);
  height: var(--dl-size-size-small);
  display: flex;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: center;
  background-color: #f5f5f5ff;
}
.home-icon23 {
  fill: #595959;
  width: 24px;
  height: 24px;
}
.home-line7 {
  flex: 1;
  height: 0px;
  display: flex;
  align-items: flex-start;
  border-color: transparent;
  border-style: dashed;
  border-width: 2px;
  flex-direction: row;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
}
.home-container21 {
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-unit);
}
.home-text53 {
  color: #ffffff;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text54 {
  color: rgb(255, 255, 255);
  font-size: 0.75rem;
  text-align: center;
}
.home-banner {
  background-color: var(--dl-color-scheme-brown);
}
.home-max-width4 {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-text57 {
  color: var(--dl-color-scheme-white);
  font-style: normal;
  text-align: center;
  font-weight: 700;
  margin-bottom: 4px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.home-text58 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  margin-bottom: var(--dl-space-space-unit);
}
.home-text59 {
  color: var(--dl-color-scheme-white);
  text-align: center;
  line-height: 26px;
  margin-bottom: var(--dl-space-space-twounits);
}
.home-primary1 {
  margin-bottom: var(--dl-space-space-threeunits);
  text-decoration: none;
}
.home-footer {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-container22 {
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}
.home-navlink08 {
  display: contents;
}
.home-image3 {
  height: 4rem;
  margin-bottom: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  margin-top: 0px;
  align-items: center;
  flex-direction: row;
}
.home-navlink09 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: 0px;
  text-decoration: none;
}
.home-navlink09:hover {
  color: #ff0000;
  font-style: normal;
  font-weight: 700;
}
.home-navlink10 {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink10:hover {
  color: red;
  font-style: normal;
  font-weight: 700;
}
.home-navlink11 {
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink11:hover {
  color: red;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.home-navlink12 {
  text-align: center;
  transition: 0.3s;
  margin-left: var(--dl-space-space-unit);
  text-decoration: none;
}
.home-navlink12:hover {
  color: #ff0000;
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
}
.home-separator {
  flex: 0 0 auto;
  width: 100%;
  height: 0px;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: flex-start;
  margin-left: 0px;
  border-color: #D9D9D9;
  border-style: solid;
  border-width: 1px;
  margin-right: 0px;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: row;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.home-container23 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-container24 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.home-container25 {
  display: flex;
  align-items: space-between;
  flex-direction: row;
}
.home-link4 {
  display: contents;
}
.home-icon25 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-link5 {
  display: contents;
}
.home-icon27 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  text-decoration: none;
}

.home-learn-more-link {
  cursor: pointer;
  text-align: center;
  transition: 0.3s;
  margin-left: 0px;
  text-decoration: underline;
  color: white;
  margin-bottom: 20px;
}

@media(max-width: 991px) {
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-mobile-menu {
    flex: 1;
    width: 100%;
  }
  .home-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.0),rgba(0, 0, 0, 0.5)),url(../assets/giphy-1500w.gif);
  }
  .home-heading-container {
    max-width: 100%;
    margin-bottom: 42px;
  }
  .home-services {
    background-image: linear-gradient(#01171e,#fff);
  }
  .home-cards-container {
    flex-direction: row;
  }
  .home-cards-container1 {
    flex-direction: row;
  }
  .home-max-width2 {
    flex-direction: column;
  }
  .home-tab-selector-cards-container {
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
  }
  .home-tab-selector-cards-container1 {
    flex-direction: row;
    grid-template-columns: repeat(2, 1fr);
  }
  .home-max-width3 {
    flex-direction: row;
  }
  .home-text-container2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-image-container {
    width: 50%;
  }
  .home-text33 {
    text-align: center;
  }
  .home-text35 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-burger-menu {
    align-items: center;
    justify-content: center;
  }
  .home-mobile-menu {
    width: 767px;
    height: 551px;
  }
  /*
  .home-sidebar {
    height: auto;
  }
  */
  .home-hero {
    background-image: linear-gradient(rgba(0, 0, 0, 0.0),rgba(0, 0, 0, 0.5)),url("../assets/giphy-1500w.gif");
  }
  .home-text07 {
    font-size: 48px;
  }
  .home-services {
    /*background-color: #01181f;*/
    box-shadow: none;
  }
  .home-cards-container {
    flex-direction: row;
  }
  .home-cards-container1 {
    flex-direction: row;
  }
  .home-tab-selector-cards-container {
    flex-direction: row;
    grid-template-columns: 1fr;
  }
  .home-tab-selector-cards-container1 {
    flex-direction: row;
    grid-template-columns: 1fr;
  }
  .home-image-container {
    display: none;
  }
  .home-steps {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-container09 {
    align-items: flex-start;
    padding-left: var(--dl-space-space-fiveunits);
    flex-direction: column;
  }
  .home-step {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container10 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line1 {
    border-color: #D9D9D9;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container12 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text38 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text40 {
    text-align: left;
  }
  .home-step1 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container13 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line2 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line3 {
    border-color: #D9D9D9;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container15 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text43 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text44 {
    text-align: left;
  }
  .home-step2 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container16 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line4 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line5 {
    border-color: #D9D9D9;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container18 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text47 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text49 {
    text-align: left;
  }
  .home-step3 {
    width: 100%;
    height: auto;
    flex-direction: row;
    justify-content: center;
  }
  .home-container19 {
    width: var(--dl-size-size-small);
    height: auto;
    align-self: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: column;
    padding-bottom: 2px;
    justify-content: space-between;
  }
  .home-line6 {
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-line7 {
    border-color: transparent;
    border-style: dashed;
    border-top-width: 0px;
    border-left-width: 2px;
  }
  .home-container21 {
    width: 100%;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-twounits);
    justify-content: space-between;
  }
  .home-text53 {
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text54 {
    text-align: left;
  }
  .home-footer {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-image3 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container23 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text60 {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .home-navbar-interactive {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    width: 479px;
    height: 655px;
    padding: 16px;
  }
  .home-hero {
    background-position: center,bottom;
  }
  .home-heading-container {
    height: 510px;
  }
  .home-primary {
    border: 1px solid;
    background-color: transparent;
  }
  .home-services {
    background-image: linear-gradient(#01171e,#fff);
  }
  .home-cards-container {
    flex-direction: column;
  }
  .home-cards-container1 {
    flex-direction: column;
  }
  .home-tab-selector-cards-container {
    height: 782px;
    flex-direction: column;
  }
  .home-tab-selector-cards-container1 {
    height: 782px;
    flex-direction: column;
  }
  .home-steps {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-container09 {
    padding-left: 0px;
  }
  .home-container12 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text38 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container15 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text43 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container18 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text47 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-container21 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: 0px;
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-text53 {
    margin-left: 0px;
    margin-right: var(--dl-space-space-twounits);
  }
  .home-footer {
    padding: var(--dl-space-space-unit);
  }
  .home-nav1 {
    flex-direction: column;
  }
  .home-navlink09 {
    margin-bottom: 10px;
  }
  .home-navlink10 {
    margin-bottom: 10px;
  }
  .home-navlink11 {
    margin-bottom: 10px;
  }
  .home-separator {
    margin-top: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
  .home-container23 {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .home-text60 {
    text-align: center;
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
.navbar-interactive-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-interactive-login-register {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.navbar-interactive-login {
  border-width: 0;
  margin-right: var(--dl-space-space-twounits);
  text-decoration: none;
}
.navbar-interactive-register {
  text-decoration: none;
}
.navbar-interactive-navlink4 {
  display: contents;
}