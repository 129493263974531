.feature-card22-feature-card {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.feature-card22-icon {
  fill: #595959;
  width: 2rem;
  height: 2rem;
  flex-shrink: 0;
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.feature-card22-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.feature-card22-text {
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}




@media(max-width: 767px) {
  .feature-card22-feature-card {
    flex-direction: row;
  }
  .feature-card22-container {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .feature-card22-container {
    flex-direction: column;
  }
}
